@use '_colors.scss' as *;

#nav-people-spacer {
    flex: 0 1 3rem;
    padding: .5rem 1rem;
}
#nav-people {
    background-color: black;
    color: white;
    display: flex;
    flex: 0 1 3rem;
    padding: .5rem 1rem;
    border-radius: 1rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    .counter {
        font-size: 1.4rem;
    }
}
#menu-people {
    left: -100vw;
    background-color: white;
    &.active {
        left: 0;
    }
    .closer {
        color: black;
        &:hover, &:focus {
            color: $blue;
        }
    }
    .menu {
        .game-code {
            font-size: 4rem;
            text-align: center;
            padding: 1rem;
            border: thin solid black;
        }
        .btn {
            width: calc(100% - .2rem);
        }
        .user-list {
            margin-top: 4rem;
            .user {
                border-bottom: thin solid black;
                padding: 1rem 0;
                font-size: 2rem;
                color: black;
                display: flex;
                justify-content: space-between;
                i, svg {
                    flex: 0 0 4rem;
                    font-size: 2rem;
                }
            }
        }
    }
}