@use '_colors.scss' as *;

.input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    width: 100%;
    &.stacked {
        margin-bottom: 0;
    }
    label {
        display: flex;
        font-family: "aller-display", sans-serif;
        color: $dark-grey;
        font-size: 1.75rem;
        margin-bottom: 1rem;
        text-transform: lowercase;
        .required {
            color: $blue;
            margin-left: 0.5rem;
        }
    }
    input {
        font-family: 'Roboto', sans-serif;
        border: 1px solid $dark-grey;
        border-radius: 0;
        font-size: 2.5rem;
        font-weight: 700;
        padding: 1rem 1.5rem;
    }
        ::placeholder {
            font-weight: 300;
            font-size: 2rem;
            font-style: italic;
        }
    textarea {
        font-family: 'Roboto', sans-serif;
        border: 1px solid $dark-grey;
        border-radius: 0;
        font-size: 2.5rem;
        font-weight: 700;
        padding: 1rem 1.5rem;
    }
        ::placeholder {
            font-weight: 300;
            font-size: 2rem;
            font-style: italic;
        }
}
form {
    .btn {
        &.black {
            background: $black;
            color: white;
            border: 1px solid $black;
            &:hover {
                background: $dark-grey;
                border: 1px solid $dark-grey;
            }
        }
    }
}