.menu-overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: .5s;
    z-index: 0;
    &.active {
        z-index: 20;
    }
    .closer {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 2.5rem;
        color: white;
        &:hover {
            cursor: pointer;
            color: black;
        }
    }
    .menu {
        height: calc(100vh - 6rem);
        padding: 6rem 3rem;
        .menu-wrapper {
            height: 100%;
            max-width: 50vh;
            min-width: 300px;
            margin: 0 auto;
            overflow: auto;
        }
        .links {
            width: 100%;
        }
    }
}