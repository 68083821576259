@use '_colors.scss' as *;

a.btn {
    text-decoration: none;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: transparent;
    color: $black;
    border: 1px solid $black;
    padding: .5rem 0 1rem 0;
    font-family: aller-display, sans-serif;
    font-size: 2.5rem;
    text-transform: lowercase;
    letter-spacing: 0.1rem;
    &:hover {
        cursor: pointer;
        background-color: $dark-grey;
        color: white;
    }
    &.black {
        background-color: $black;
        color: white;
        &:hover {
            background-color: $dark-grey;
        }
    }
    &.text-only {
        background-color: white;
        border-color: white;
        color: black;
    }
    &.blue {
        background-color: $blue;
        border-color: $blue;
        color: white;
    }
    &.red {
        background-color: $red;
        border-color: $red;
        color: white;
    }
    &.white-on-red {
        background-color: white;
        border-color: $red;
        color: $red;
        &:hover {
            background-color: $red;
            border-color: white;
            color: white;
        }
    }
    &.sm {
        font-size: 2rem;
    }
    &.submit {
        background-color: $blue;
        border-color: $blue;
        color: white;
        &.disabled {
            background-color: $md-grey;
            border-color: $md-grey;
            &:hover {
                cursor: default;
            }
        }
    }
    &.select {
        &:hover {
            background-color: $md-grey;
            color: $dark-grey;
        }
        &.active {
            background-color: $dark-grey;
            color: white;
        }

    }
}