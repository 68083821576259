@use "_reboot.scss";
@use "_fonts.scss";
@use "_colors.scss" as *;
@use "_buttons.scss";
@use "_forms.scss";
@use "_helpers.scss";
@use "_nav.scss";
@use "_nav_main.scss";
@use "_nav_people.scss";
@use "_popover.scss";
@use "_modal.scss";

body, html {
    font-size: 10px;
    font-family: 'Roboto', sans-serif;
    background-color: $blue;
}

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
    background-color: white;
}

::-webkit-scrollbar
{
    width: 6px;
}

::-webkit-scrollbar-thumb
{
    background-color: #000;
}

#header-logo {
    width: 25rem;
    margin: 0 auto;
}

#app-wrapper {
    font-size: 1.2rem;
    background-color: $blue;
    .app-container {
        // background-color: rgba(100,0,0,.1);
        margin: 0 auto;
        height: 100vh;
        min-height: 600px;
        width: 50vh;
        min-width: 350px;
        max-width: 100vw;
    }
    .setup-pages, .welcome-page {
        padding: 1.5rem;
        height: calc(100% - 3rem);
        width: calc(100% - 3rem);
        background-color: white;
    }
    .racoon-loader {
        font-size: 2.3rem;
        text-transform: lowercase;
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
    }
    .header-area {
        width: 100%;
        flex: 0 0 5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 5rem;
        z-index: 5;
        top: 0;
        .menu-icon {
            position: relative;
            .menu-bars {
                &:hover {
                    cursor: pointer;
                    color: $md-dark-grey;
                }
            }
            .menu-flyout {
                position: absolute;
                background-color: white;
                right: 0;
                border: 1px solid;
                display: flex;
                flex-direction: column;
                min-width: 125px;
                &.show {
                    visibility: visible;
                }
                &.hide {
                    visibility: hidden;
                }
                a {
                    padding: .75rem 1rem;
                    text-decoration: none;
                    color: black;
                    font-size: 1.6rem;
                    border-bottom: 1px solid;
                    &:last-child {
                        border-bottom: none;
                    }
                    &:hover {
                        background-color: $blue;
                        color: white;
                        border-bottom-color: black;
                    }
                
                }
            }
        }
    }
    .welcome-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            margin-bottom: 5rem;
        }
        .btn {
            margin-bottom: 3rem;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .made-by {
        // height: 150px;
        margin-top: 3rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 300;
        font-size: 1.1rem;
        img {
            margin: 0;
        }
        .logo-wrapper {
            margin: .5rem 0;
        }
        .shs {
            font-size: 1.5rem;
            color: black;
            text-decoration: none;
        }
    }

    .setup-pages {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        justify-content: flex-start;
        .content {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            // height: 100%;
            overflow: hidden;
            margin-top: 1rem;
            .content-wrapper {
                text-align: center;
                padding-top: 2rem;
                h1 {
                    margin: 0;
                    line-height: .8;
                }
                h2.game-name {
                    text-align: center;
                    color: $blue;
                    font-family: "aller-display", sans-serif;
                    font-size: 3rem;
                    line-height: .8;
                    margin: 0;
                    margin-bottom: 1.5rem;
                }
                p {
                    font-weight: 700;
                    line-height: 1.5;
                }   
            }
        }
        .action {
            flex: 0 0 8.4rem;
            width: 100%;
            height: 8.4rem;
            z-index: 2;
            bottom: 0;
            text-align: center;
            h3 {
                margin: .5rem 0;
            }
        }
        .games-list {
            .game {
                border: 1px solid black;
                margin-bottom: 1rem;
                font-size: 2.5rem;
                padding: 1rem 1rem 1.5rem 1rem;
                a {
                    text-decoration: none;
                    font-family: "aller-display", sans-serif;
                    color: black;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .game-name {
                        position: relative;
                        z-index: 1;
                        padding-right: 1rem;
                        line-clamp: 1;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                    }
                    .game-status {
                        text-transform: lowercase;
                        position: relative;
                        padding-right: 1rem;
                        display: block;
                        font-size: 2rem;
                        &:after {
                            content: "";
                            position: absolute;
                            top: .8rem;
                            right: -1rem;
                            display: block;
                            width: .5rem;
                            height: 0;
                            border: .5rem solid transparent;
                            border-left-color: black;
                            z-index: 1;
                        }
                        &.complete {
                            padding-right: 0rem;
                            &:after {
                                border: none;
                                background-image: url(../imgs/dot-01.jpg);
                                width: 6.5rem;
                                height: 6.5rem;
                                right: 1.5rem;
                                top: -2rem;
                                background-size: contain;
                                mix-blend-mode: multiply;
                                background-repeat: no-repeat;
                                filter: grayscale(100%);
                                opacity: .5;
                            }
                        }
                    }
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .entries-wrapper {
            margin: 1rem 0;
            overflow-y: scroll;
            flex: 1 1 auto;
            .entries-list {
                .entry {
                    border-bottom: 1px solid black;
                    font-size: 1.6rem;
                    font-weight: 700;
                    padding: 1.5rem 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p {
                        font-weight: 700;
                        line-height: 1.5;
                    }  
                    .entry-sequence {
                        flex: 0 0 40px;
                        border: 1px solid;
                    }
                    .entry-info {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        align-self: center;
                        justify-content: center;
                        .entry-text {
                            font-size: 2.5rem;
                            font-weight: 600;
                            font-family: 'Roboto', sans-serif;
                        }
                        .entry-author {
                            font-size: 1.2rem;
                            font-weight: 400;
                            color: #000;
                        }
                    }
                    .entry-actions {
                        flex: 0 0 25px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        color: $md-dark-grey;
                        .action-icon {
                            &:hover {
                                cursor: pointer;
                                color: $dark-grey;
                            }
                        }
                    }
                }
            }
        }
        &.create-a-game {
            a.btn.black {
                width: calc(100% - .2rem);
            }
        }
        &.join-game {
            // height: unset;
            .content {
                flex: 0 0 auto;
            }
            .action {
                margin-top: 3rem;
            }
        }
        h3 {
            margin-top: 0;
            margin-bottom: 0;
        }
        .entry-count {
            text-align: center;
            color: $red;
            border-top: thin solid $red;
            border-bottom: thin solid $red;
            padding: .5rem 0;

        }
    }
    .game-page {
        height: calc(100% - 3rem);
        width: calc(100% - 3rem);
        padding: 1.5rem;
        background: $blue;
        display: flex;
        flex-direction: column;
        .game-menu {
            border: 1px solid white;
            flex: 0 0 40px;
        }
        .game-board {
            flex: 0 0 calc(50% - 20px - 1.5rem);
            padding: 1rem;
            .tile-wrapper {
                background-color: white;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                height: 100%;
                .tile {
                    box-sizing: border-box;
                    flex: 0 0 20%;
                    height: 20%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: "aller-display", sans-serif;
                    font-size: 3rem;
                    position: relative;
                    span {
                        position: relative;
                        z-index: 2;
                        font-family: 'Gravitas One';
                        font-size: 2.5rem;
                    }
                    &.rightBorder {
                        border-right: 1px solid black;
                    }
                    &.bottomBorder {
                        border-bottom: 1px solid black;
                    }
                    &.selected {
                        background-color: #DEDEDE !important;
                        // color: $blue;
                    }
                    &:nth-child(5n)  {
                        border-right: none !important;
                    }
                    &.marked {
                        color: black;
                        background-color: white !important;
                        &:before {
                            content: '';
                            z-index: 1;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url(../imgs/dot-01.jpg);
                            mix-blend-mode: multiply;
                            width: 100%;
                            height: 100%;
                            background-size: 95%;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                    .free {
                        font-size: 2rem;
                        border-top: 4px solid black;
                        border-bottom: 4px solid black;
                        font-style: italic;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0;
                        margin: 0;
                        line-height: 1.5;
                        &:before {
                            content: '';
                            z-index: 1;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-image: url(../imgs/dot-01.jpg);
                            mix-blend-mode: multiply;
                            width: 100%;
                            height: 100%;
                            background-size: 95%;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                    
                }
            }
        }
        .entries-wrapper {
            flex: 0 0 calc(50% - 7rem);
            overflow-y: scroll;
            margin: 0 1rem;
            position: relative;
            .call-bingo {
                position: sticky;
                top: 0;
                z-index: 4;
            }
            .entries-list {
                background-color: white;
                .entry {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 1.25rem 1rem;
                    border: 1px solid transparent;
                    border-bottom: 1px solid black;
                    &.not-on-board {
                        pointer-events: none;
                        & > div {
                            opacity: .35;
                        }
                        .entry-sequence {
                            &::before {
                                filter: grayscale(100%);
                                opacity: .5;
                            }
                        }
                    }
                    &.hovered {
                        border-color: black;
                        border-top-color: transparent;
                        background-color: #DEDEDE;
                    }
                    &:hover, &.selected {
                        border-color: black;
                        border-top-color: transparent;
                        background-color: #DEDEDE;
                    }
                    // &:first-child {
                    //     border-top: 1px solid black;
                    // }
                    .entry-sequence {
                        flex: 0 0 40px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-size: 3rem;
                        font-family: "aller-display", sans-serif;
                        position: relative;
                        height: 3.5rem;
                        span {
                            position: relative;
                            z-index: 2;
                            font-family: 'Gravitas One';
                            font-size: 2.5rem;
                            line-height: 2;
                        }
                        &.marked {
                            &:before {
                                content: '';
                                z-index: 1;
                                display: block;
                                position: absolute;
                                top: -8%;
                                left: -10%;
                                background-image: url(../imgs/dot-01.jpg);
                                mix-blend-mode: multiply;
                                width: 120%;
                                height: 120%;
                                background-size: 100%;
                                background-position: center;
                                background-repeat: no-repeat;
                            }
                        }
                    }
                    .entry-info {
                        flex: 1 1 auto;
                        display: flex;
                        flex-direction: column;
                        align-self: center;
                        justify-content: center;
                        padding-left: .5rem;
                        .entry-text {
                            font-size: 1.5rem;
                            font-weight: 700;
                        }
                    }
                    .entry-actions {
                        flex: 0 0 25px;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        color: $md-dark-grey;
                        .action-icon {
                            font-size: 2rem;
                            &:hover {
                                cursor: pointer;
                                color: $dark-grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .alert {
        background-color: palegoldenrod;
        padding: 1rem;
    }
}

#payment-form {
    margin: 0.05rem;
}

.winner {
    margin: 2rem 0 3rem 0;
    text-align: center;
    font-family: 'aller-display', sans-serif;
    font-size: 4rem;
    line-height: 1;
}

.small-close {
    margin-top: 1.5rem;
    font-family: 'aller-display', sans-serif;
    color: white;
    font-size: 2rem;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}
