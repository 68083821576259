@use '_colors.scss' as *;

.popover-wrapper {
    position: relative;
    .display {
        position: relative;
        width: fit-content;
        &:hover {
            cursor: pointer;
            text-decoration: underline;
            sup {
                text-decoration: none;
            }
        }
        sup {
            color: $md-grey;
            position: absolute;
            top: 5px;
            right: -5px;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .popover {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: -2rem;
        right: -1rem;
        min-height: 30px;
        min-width: 150px;
        background-color: white;
        border: 1px solid $blue-fade;
        border-radius: 7px;
        box-shadow: rgba(77,77,77,.2) 1px 1px 15px;
        padding: 1.5rem 1rem 1rem 1rem;
        font-size: 2rem;
        .popover-closer {
            position: absolute;
            top: -.5rem;
            right: 0;
            padding: 1rem;
            color: $md-grey;
            cursor: pointer;
            font-size: 2rem;
        }
        .popover-content {
            .flex-table {
                margin-top: .75rem;
                font-size: 1.5rem;
                color: black;
                .left-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: nowrap;
                    &.bordered {
                        border-bottom: 1px solid $blue-fade;
                        padding-bottom: .5rem;
                        margin-bottom: .5rem;
                    }
                }
            }
        }
        .popover-radio-input {
            display: flex;
            margin: 1rem auto;
            input {
                margin-right: 1rem;
            }
        }
        a {
            text-align: center;
            margin-top: 1rem;
        }
    }
}
