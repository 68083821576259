@use '_colors.scss' as *;

.modal {
    .modal-dialog {
        box-shadow: rgba(77, 77, 77, .2) 1px 1px 15px;
        background: white;
        position: fixed;
        top: 5vh;
        left: 50%;
        transform: translate(-50%, 0);
        overflow: auto;
        z-index: 5000;
        max-width: 1200px;
        max-height: 960px;
        min-width: 300px;
        max-height: 90vh;

        &.red {
            background-color: $red;
            color: white;
        }

        .modal-content {
            border: none;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 100%;
            padding: 4rem;
            position: relative;
            z-index: 200;

            .modal-column {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .close-icon {
                position: relative;
                top: -2rem;
                left: 100%;
                z-index: 100;
                font-size: 2rem;

                &:hover {
                    cursor: pointer;
                    color: $blue;
                }
            }
        }
    }

    .modal-backdrop {
        opacity: .6;
        z-index: 1;
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        background-color: black;
    }
}