@use '_colors.scss' as *;

#nav-main {
    flex: 0 0 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: black;
    z-index: 10;
}
#menu-main {
    right: -100vw;
    background-color: $blue;
    &.active {
        right: 0;
    }
    .closer {
        color: #000;
        &:hover, &:focus {
            color: $black;
        }
    }
    .menu {
        // max-width: 80rem;
        // margin: 0 auto;
        .links {
            a {
                font-family: 'aller-display', sans-serif;
                color: #000;
                font-size: 3rem;
                padding: 1.5rem 2rem 3rem 2rem;
                text-decoration: none;
                text-transform: lowercase;
                font-weight: 500;
                display: block;
                margin: 1rem auto;
                width: calc(100% - 6rem);
                border-bottom: thin solid #000;
            }
        }
    }
}