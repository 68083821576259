@import "https://use.typekit.net/qjz4pfe.css";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Stint+Ultra+Condensed&display=swap";
@import "https://fonts.googleapis.com/css2?family=Gravitas+One&display=swap";
h1 {
    font-family: "aller-display", sans-serif;
    font-size: 3.2rem;
    text-transform: lowercase;
    font-weight: 400;
    margin: 0;
    letter-spacing: 0.1rem;
}

p {
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.txt-center {
    text-align: center;
}